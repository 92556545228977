<template>
	<main>
        <HeaderTab :title="$t('invoice.devis.document')"/>

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
				        <div class="box">
				        	<CustomTable
				        		ref="table"
				                id_table="quotation_document"
				                :items="documents"
				                :busy.sync="table_busy"
				                primaryKey="quotationdocument_id"
				                :hide_if_empty="true"
				                :externSlotColumns="['preview']"
				                :columsAddBegin="['preview']"
				            >
				                <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
				                    <a href="" class="" @click.prevent="quickPreview(data)">
				                        <font-awesome-icon :icon="['fas', 'eye']" />
				                    </a>
				                </template>
				        	</CustomTable>
				        </div>
				    </div>
				</div>
			</div>
		</div>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="preview = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="preview != null" :src="preview" height="1000px" width="100%" style="position:relative"></iframe>
        </b-modal>

        <ModalDevisDocument ref="modal_document" :quotation_id="quotation_id" @submit="init_component"/>
        <ModalSendDocumentDevis ref="modal_send_document" :quotation_id="quotation_id" @submit="init_component" />
	</main>
</template>

<script type="text/javascript">
    import Quotation from "@/mixins/Quotation.js"
    import Navigation from "@/mixins/Navigation.js"
    import { EventBus } from "EventBus"

	export default {
		name: 'DevisDocument',
		mixins: [Quotation, Navigation],
		props: ['quotation_id'],
		data () {
			return {
				documents: [],
				table_busy: false,
				preview: null,
                events_tab: {
                    'TableAction::goToAddQuotationDocument': () => {
                        this.$refs.modal_document.openModal()
                    },
                    'TableAction::goToEditQuotationDocument': (doc) => {
                        this.$refs.modal_document.openModal(doc)
                    },
                    'TableAction::goToSendQuotationDocument': (doc) => {
						this.$refs.modal_send_document.openModal(doc)
                    },
                    'TableAction::goToDeleteQuotationDocument': this.deleteDocument,
                    'TableAction::goToDownloadQuotationDocument': this.download
                },
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.documents = await this.getDocumentByQuotation(this.quotation_id)
				this.table_busy = false
			},
			download(doc) {
				const link = document.createElement("a");
				link.href = doc.quotationdocument_s3_url;
				link.download = doc.quotationdocument_filename;
				link.click();
			},
			async deleteDocument(doc) {
				await this.deleteQuotationDocument(this.quotation_id, doc.quotationdocument_id)
				this.init_component()
				this.$refs.table.refreshTable()
				EventBus.$emit('TableAction::stopSpin')
			},
            async quickPreview(doc) {
                this.$refs.modelPreview.show()
                this.preview = doc.quotationdocument_s3_url
            }
		},

		computed: {
		},

		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ModalDevisDocument: () => import('@/components/Invoice/ModalDevisDocument'),
            ModalSendDocumentDevis: () => import('@/components/Invoice/ModalSendDocumentDevis')
		}
	}

</script>